import * as firebase from "firebase";
import "firebase/database";

let config = {
  apiKey: "AIzaSyAqchCRa1CpGFCYdAYEzP9LHb5i9mAjGoo",
  authDomain: "djjaylyricsapp.firebaseapp.com",
  databaseURL: "https://djjaylyricsapp-default-rtdb.firebaseio.com",
  projectId: "djjaylyricsapp",
  storageBucket: "djjaylyricsapp.appspot.com",
  messagingSenderId: "673762032089",
  appId: "1:673762032089:web:9ebf0dd457658b9c3781c8",
  measurementId: "G-ETZJ3XW1HE"
};



firebase.initializeApp(config);

export default firebase.database();